.introduction-content img {
  max-width: 100%;
  /* Ensure images don't overflow */
  height: auto;
  /* Maintain aspect ratio */
}

.introduction-content {
  word-break: break-word;
  overflow: scroll;
  max-height: 100px;
  /* Prevent long words from overflowing */
}

.ck {
  height: 500px;
  /* Adjust the height as needed */
}


.required-field::after {
  content: " *";
  color: red;
}

.custom-btn {
  background-color: #4f46e5 !important;
  /* indigo-600 */
  border-color: #4f46e5 !important;
  color: white !important;
  transition: background-color 0.3s ease, color 0.3s ease !important;
}

.custom-btn:hover {
  background-color: #5800B0 !important;
  /* indigo-700 on hover */
  color: white !important;
  /* keep the text white */
}

.custom-btn:disabled {
  background-color: #cbd5e1;
  /* a lighter gray for disabled state */
  border-color: #cbd5e1;
  color: #9ca3af;
}

figure img {
  height: auto;
}

.blog-style ol {
  list-style-type: disc;
  color: #343a40;
  padding-left: 20px;
}


.blog-style ul {
  list-style-type: disc;
  color: #343a40;
  padding-left: 20px;
}

.blog-style h2{
  font-size: xx-large;
  font-weight: bolder;
  margin-top: 30px !important;
}

.blog-style h3{
  font-size: x-large;
  font-weight: bolder;
  margin-top: 20px;
}
